module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-antd-starter","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9665b2e8068cdb2f74374f6930db24fb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mixpanel/gatsby-browser.js'),
      options: {"plugins":[],"apiToken":"cce218d358f292bb20230c5408ec3d08"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
